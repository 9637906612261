import React, { useState } from 'react';
import Layout from '../components/layout'
import Accordion from '../components/accordianModal';

import building from "../images/graphic/gov/buildingEnvelope.png";
import ev from "../images/graphic/gov/ev.png";
import lighting from "../images/graphic/gov/lighting.png";
import service from "../images/graphic/gov/serviceWaterAdjustment.png";
import spaceHeating from "../images/graphic/gov/spaceHeating.png";
import water from "../images/graphic/gov/waterConservation.png";

import mainIMG from "../images/graphic/gov/main.png";

const GraphicIndustrial = (props) => {
    
    const modalList = [
        {
            title: "EV Charging",
            desc: "Our advisory services help you plan the optimal electric vehicle strategy for your organization, and our team can help you find the right technology partners and incentives to maximize your ROI. Our award-winning support team are here to make you start seeing benefits from day one.",
            desc2: "We are able to integrate your charging stations with your facility for full compatibility, no matter the system type.",
            cost: "5",
            energy: "3",
            pay: "5",
            Akey: ev,
        },
        {
            title: "Building Envelope",
            desc: "Full energy-loss audits identify all possible energy conservation measure – resulting in the best energy and cost savings based on your local climate and weather patterns.",
            desc2: "",
            cost: "1",
            energy: "2",
            pay: "4",
            Akey: building,
        },
        {
            title: "Space Heating and Cooling",
            desc: "HVAC can represent a considerable portion of a facility’s energy use. There are many ways to improve HVAC efficiency, from adjusting fan operation during unoccupied hours and implementing energy management systems to installing economizers, utilizing heat recovery, minimizing exhaust and make-up air, and upgrading to the latest equipment.",
            desc2: "Implementing a modern climate control system gives you insights into your building’s energy performance while allowing you to implement system setpoints, establish custom climate zones and occupancy controls, and adjust operating hours — which can all reduce your HVAC energy use and save you money.",
            cost: "6",
            energy: "6",
            pay: "6",
            Akey: spaceHeating,
        },
        {
            title: "Lighting",
            desc: "Switching to LEDs presents one of the easiest energy efficiency programs for facilities to implement. Lighting upgrades have a short payback period, typically realizing ROI between one and three years. Additionally, modern high-efficiency LED light sources provide more illumination at a lower operating cost — meaning you can improve your work environment while also improving energy performance.",
            desc2: "Beyond upgrading to LED lights, occupancy sensors offer additional energy-saving opportunities.",
            cost: "3",
            energy: "4",
            pay: "2",
            Akey: lighting,
        },
        {
            title: "Service Water Adjustment",
            desc: "Tankless water heaters, also known as instantaneous water heaters, provide hot water on a continuous, as-needed basis. This makes them more efficient than boilers that utilize storage tanks because it eliminates any standby losses.",
            desc2: "",
            cost: "2",
            energy: "1",
            pay: "3",
            Akey: service,
        },
        {
            title: "Water Conservation",
            desc: "Low flow faucets and sensor controls reduce flow rates, decrease hot water use and lower overall energy and water consumption.",
            desc2: "",
            cost: "4",
            energy: "5",
            pay: "1",
            Akey: water,
        },
    ];
    let param = props.location.search;
    let filter = param.slice(8);
    const [filterName, setfilterName] = useState('this string');

    
    let buttonSelctor = (buttonID) => {
        let btns = document.querySelectorAll('.filterBtn'); 
        btns.forEach(element => element.classList.remove('choosenFilter'));
        if(filterCost === true || filterEnergy === true || filterCarbon === true){
            document.querySelector("#"+buttonID).classList.add('choosenFilter');
        }
    }
    const [dataObject, setdataObject] = useState(modalList);
    
    const costSort = () => {
        buttonSelctor('cost');
        setfilterName("Cost Reduction");
        
        setdataObject((modalList) => {
          const dataToSort = [...modalList];
          dataToSort.sort((a, b) => Number(a.cost) - Number(b.cost));
          return dataToSort;
        });
        
    };
    const energySort = () => {
        buttonSelctor('energy');
        setfilterName("Energy Usage Reduction");
        
        setdataObject((modalList) => {
          const dataToSort = [...modalList];
          dataToSort.sort((a, b) => Number(a.energy) - Number(b.energy));
          return dataToSort;
        });
    };
    const paySort = () => {
        buttonSelctor('carbon');
        setfilterName("Carbon Reduction");
        
        setdataObject((modalList) => {
          const dataToSort = [...modalList];
          dataToSort.sort((a, b) => Number(a.pay) - Number(b.pay));
          return dataToSort;
        });
    };
    
    const [Image, setImage] = useState(mainIMG);
    const setImageVar = (e) => {
        if(Image === e){
            setImage(mainIMG);
        }
        else{
            setImage(e);
        }
    }
    
    const [filterCost, setfilterCost] = useState(false);
    if(filterCost !== true && filter === 'cost'){
        costSort();
        setfilterCost(true);
    }

    const [filterEnergy, setfilterEnergy] = useState(false);
    if(filterEnergy !== true && filter === 'energy'){
        energySort();
        setfilterEnergy(true);
    }

    const [filterCarbon, setfilterCarbon] = useState(false);
    if(filterCarbon !== true && filter === 'carbon'){
        paySort();
        setfilterCarbon(true);
    }

    return (
        <Layout nextBottomSlideBtn="Implementation" nextBottomSlideBtnLink="/Implementation" id="works">
            <ul className="priceNav">
                <strong>Sort by:</strong>
                <li onClick={costSort}><button className="filterBtn" id="cost" >Cost Reduction</button></li>
                <li onClick={paySort}><button className="filterBtn" id="carbon">Carbon Reduction</button></li>
                <li onClick={energySort}><button className="filterBtn" id="energy">Energy Usage Reduction</button></li>
            </ul>
            <div className="fullWidth">
                <div className="buttons">
                    <h1 id="filterName"><strong>{filterName}</strong></h1>
                    <br></br>
                    <div className="options">
                        {dataObject.map((data) => (
                            <Accordion 
                                title={data.title} 
                                desc={data.desc}
                                desc2={data.desc2}
                                annualCost={data.annualCost}
                                payback={data.paybac}
                                annualCarbon={data.annualCarbon}
                                upfront={data.upfront}
                                cost={data.cost} 
                                energy={data.energy} 
                                pay={data.pay}
                                Akey={data.Akey}
                                onClick = { () => setImageVar(data.Akey)}
                            />
                        ))}
                    </div>
                </div>
                <div className="graphic">
                    <img
                        alt="Government"
                        src= {Image}
                    />
                </div>
            </div>
        </Layout>
    )
}

export default GraphicIndustrial